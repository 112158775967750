<template lang="pug">
.textual-datepicker(:class="{'empty': dateValue === null}")
  b-datepicker(:value="dateValue" :placeholder="placeholder" :min-date="minDate" :date-parser="dateParser" :date-formatter="dateFormatter" :append-to-body="true" @input="updateValue" :mobile-native="false")
  a.clear-input.has-text-grey(v-if="value" @click="$emit('input', null)")
    b-icon(icon="times")
</template>

<script>
import dayjs from '@/dayjs'
import { dateParser, dateFormatter } from '@/misc/helpers'

export default {
  name: 'TextualDatepicker',
  props: {
    value: String,
    placeholder: String,
    minDate: Date
  },
  computed: {
    dateValue() {
      const { value } = this
      if (value) {
        return this.dateParser(value)
      }
      return null
    }
  },
  methods: {
    dateParser,
    dateFormatter,
    updateValue(date) {
      this.$emit('input', dayjs(date).format('YYYY-MM-DD'))
    }
  }
}
</script>

<style lang="sass" scoped>
.textual-datepicker
  position: relative
  margin: 0.25rem
.datepicker
  width: auto
  max-width: 140px
.empty .datepicker
  max-width: 80px
::v-deep input
  border-width: 0
  background: none
  box-shadow: none !important
  font-weight: bold
  padding: 0 0.25rem
  height: auto
  border-bottom: 2px solid #aaa
  border-radius: 0
  &::placeholder
    color: #666
    text-align: center
    font-weight: normal
.clear-input
  position: absolute
  top: 0
  bottom: 0.1rem
  right: 0
  display: flex
  align-items: center
</style>
