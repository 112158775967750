<template lang="pug">
.simulator-output
  button-set(v-model="simulationEnd" :options="endOptions")
  b-message(type="warning" title="Cycle detected" v-if="hasHitIterLimit")
    p
      | Unfortunately while simulating your finances, we detected a cycle so the
      | result may be inaccurate. This can occur when you are paying off two
      | liabilities and direct each to pay into the other once paid off. Please
      | edit your simulator settings to resolve the issue.
  multi-line(:datasets='projectionData' :currency='activeCurrencyWithSubunit' granularity='year' chart-id="simulator-output-chart")
  scenarios-config
  .scrolling-cols(v-if="samples && samples[0]")
    .start-col
      h3(v-if="scenarioSamples") With scenarios
      .date Year
      template(v-if="scenarioSamples")
        .asset(v-for="asset in samples[0].assets" :key="asset.id")
          | {{ asset.name }}
        .liability(v-for="liability in samples[0].liabilities" :key="liability.id")
          | {{ liability.name }}
        .net-worth Net Worth
        .diff Δ Scenarios
        h3 Without scenarios
      .asset(v-for="asset in samples[0].assets" :key="asset.id")
        | {{ asset.name }}
      .liability(v-for="liability in samples[0].liabilities" :key="liability.id")
        | {{ liability.name }}
      .net-worth Net Worth
    .scroller.fancy-scrollbar
      .col(v-for="(sample, idx) in samples" :key="+sample.date")
        h3.spacer(v-if="scenarioSamples")
        .date {{ idx ? sample.date.getFullYear() : 'Start' }}
        template(v-if="scenarioSamples && scenarioSamples[idx]")
          .asset(v-for="asset in scenarioSamples[idx].assets" :key="asset.id")
            | {{ asset.amount | currencyFormatter(activeCurrencyWithSubunit) }}
          .liability(v-for="liability in scenarioSamples[idx].liabilities" :key="liability.id")
            | {{ liability.amount | currencyFormatter(activeCurrencyWithSubunit) }}
          .net-worth {{ scenarioSamples[idx].netWorth | currencyFormatter(activeCurrencyWithSubunit) }}
          .diff
            diff-span(:value="scenarioSamples[idx].netWorth - samples[idx].netWorth" :currency="activeCurrencyWithSubunit")
          h3.spacer
        .asset(v-for="asset in sample.assets" :key="asset.id")
          | {{ asset.amount | currencyFormatter(activeCurrencyWithSubunit) }}
        .liability(v-for="liability in sample.liabilities" :key="liability.id")
          | {{ liability.amount | currencyFormatter(activeCurrencyWithSubunit) }}
        .net-worth {{ sample.netWorth | currencyFormatter(activeCurrencyWithSubunit) }}
</template>
<script>
import { sortBy } from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'
import dayjs from '@/dayjs'

import currencyFormatter from '@/misc/currencyFormatter'
import { runSimulatorJSON } from '@/workers/simulator.worker'

import ButtonSet from '@/components/ButtonSet.vue'
import MultiLine from '@/components/charts/MultiLine.vue'
import ScenariosConfig from '@/components/scenarios/Config.vue'
import DiffSpan from '@/components/DiffSpan.vue'

export default {
  name: 'SimulatorOutput',
  components: {
    ButtonSet,
    MultiLine,
    ScenariosConfig,
    DiffSpan
  },
  filters: {
    currencyFormatter
  },
  data() {
    return {
      hasHitIterLimit: false,
      endOptions: [
        { value: 5, name: '5 years' },
        { value: 10, name: '10' },
        { value: 20, name: '20' },
        { value: 30, name: '30' },
        { value: 50, name: '50' }
      ],
      simulationEnd: Number(localStorage.getItem('simEndPreference') || '15'),
      isUpdating: false,
      scenarioSamples: null
    }
  },
  computed: {
    ...mapState('entries', ['entries']),
    ...mapGetters('currencies', ['activeCurrencyWithSubunit']),
    ...mapState('categories', ['categories']),
    ...mapGetters('scenarios', ['activeScenarios']),
    lastEntry() {
      const entry = this.entries[0]
      if (entry) {
        // Enforce custom ordering
        return {
          ...entry,
          assets: sortBy(entry.assets, ({ categoryId }) =>
            this.$store.getters['categories/categoryWeight'](categoryId)
          ),
          liabilities: sortBy(entry.liabilities, ({ categoryId }) =>
            this.$store.getters['categories/categoryWeight'](categoryId)
          )
        }
      }
      return null
    },
    projectionData() {
      const { samples, scenarioSamples, isUpdating } = this
      if (samples === null || isUpdating) {
        return null
      }
      const data = samples.map(({ date, netWorth }) => ({
        t: date,
        y: netWorth
      }))
      const series = [
        {
          label: 'Predicted Net Worth',
          color: 'rgba(0, 112, 255, .5)',
          data
        }
      ]
      if (scenarioSamples) {
        const scenarioData = scenarioSamples.map(({ date, netWorth }) => ({
          t: date,
          y: netWorth
        }))
        series.push({
          label: 'With Scenarios',
          color: 'rgba(0, 200, 112, .5)',
          data: scenarioData
        })
      }
      return series
    }
  },
  asyncComputed: {
    async samples() {
      this.isUpdating = true
      this.hasHitIterLimit = false
      const {
        lastEntry,
        simulationEnd,
        activeCurrencyWithSubunit,
        activeScenarios,
        categories
      } = this
      if (!lastEntry || !activeCurrencyWithSubunit || !categories) {
        this.isUpdating = false
        return null
      }
      try {
        const sims = {
          default: { scenarios: [] }
        }
        if (activeScenarios.length) {
          sims.scenarios = { scenarios: activeScenarios }
        }
        // Assume rate is constant
        const rate = await this.getExchangeRateOn({
          date: lastEntry.date,
          currency: activeCurrencyWithSubunit
        })
        const params = {
          startEntry: lastEntry,
          endDate: dayjs()
            .add(simulationEnd, 'years')
            .startOf('year')
            .toDate(),
          rate,
          categories,
          sims
        }
        const result = await runSimulatorJSON(JSON.stringify(params))
        this.hasHitIterLimit = result.default.hasHitIterLimit
        // Scenario samples
        if ('scenarios' in result) {
          if (result.scenarios.hasHitIterLimit) {
            this.hasHitIterLimit = true
          }
          this.scenarioSamples = result.scenarios.samples
        } else {
          this.scenarioSamples = null
        }
        this.isUpdating = false
        return result.default.samples
      } catch (e) {
        this.isUpdating = false
        console.error(e)
        return null
      }
    }
  },
  watch: {
    simulationEnd(v) {
      localStorage.setItem('simEndPreference', v.toString())
    },
    isUpdating: {
      immediate: true,
      handler(val) {
        if (val) this.$parent.$emit('update:start')
        else this.$parent.$emit('update:done')
      }
    }
  },
  methods: {
    ...mapActions('currencies', ['getExchangeRateOn'])
  }
}
</script>

<style lang="sass" scoped>
.scrolling-cols
  display: flex
  > *
    white-space: nowrap
.date
  font-weight: bold
  padding-bottom: 0.5rem
.net-worth
  padding-top: 0.5rem
  font-weight: bold
.start-col
  padding-right: 0.75rem
  max-width: 40%
  > *
    text-overflow: ellipsis
    max-width: 100%
    width: 100%
    overflow: hidden
  > h3
    width: 2rem
    overflow: visible
.scroller
  flex-grow: 1
  overflow-x: auto
  overflow-y: hidden
  display: grid
  grid-auto-columns: 1fr
  grid-auto-flow: column
  scroll-snap-type: x mandatory
  padding-bottom: 0.5rem
  .col
    scroll-snap-align: start
    padding: 0 0.75rem
  .date
    text-align: center
  .asset, .liability, .net-worth, .diff
    text-align: right
.diff
  font-size: 0.9rem
h3
  display: block
  height: 2rem
  padding-top: 1rem
  margin-bottom: 0.5rem
  font-size: 0.9rem
  color: #777
</style>
<style lang="sass">
div.fancy-scrollbar
  scrollbar-width: thin
  scrollbar-color: transparent
  &::-webkit-scrollbar
    height: 8px
  &::-webkit-scrollbar-track
    background: #ddd
  &::-webkit-scrollbar-thumb
    background-color: #777
    border-radius: 6px
</style>
