<template lang="pug">
multi-line(:datasets='basicProjectionData' :currency='activeCurrencyWithSubunit' granularity='month' label="Predicted Net Worth" chart-id="basic-projection-chart")
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { sortBy, groupBy, sumBy, last } from 'lodash'
import dayjs from '@/dayjs'
import basicProjection from '@/workers/basicProjection.worker.js'

import MultiLine from '@/components/charts/MultiLine.vue'

export default {
  components: {
    MultiLine
  },
  props: {
    method: String
  },
  data() {
    return {
      isUpdating: false
    }
  },
  computed: {
    ...mapGetters('currencies', ['activeCurrencyWithSubunit']),
    ...mapState('entries', ['entries']),
    groupedEntries() {
      if (!this.entries.length) {
        return null
      }
      const entries = groupBy(this.entries, entry => {
        // Group by month
        return entry.id.slice(0, 7)
      })
      // Clip to max 12 data points
      const keys = Object.keys(entries)
        .sort()
        .reverse()
      const clippedEntries = {}
      keys
        .slice(0, 12)
        .reverse()
        .forEach(k => {
          clippedEntries[k] = entries[k]
        })
      return clippedEntries
    },
    firstDate() {
      if (!this.groupedEntries) {
        return null
      }
      const sortedDates = Object.keys(this.groupedEntries).sort()
      return dayjs(sortedDates[0], 'YYYY-MM')
    },
    lastDate() {
      if (!this.groupedEntries) {
        return null
      }
      const sortedDates = Object.keys(this.groupedEntries).sort()
      return dayjs(sortedDates[sortedDates.length - 1], 'YYYY-MM')
    }
  },
  asyncComputed: {
    async sourceData() {
      const { activeCurrencyWithSubunit, groupedEntries } = this
      if (!activeCurrencyWithSubunit) {
        return null
      }
      // Build a time/series to regress
      const series = []
      const promises = []

      Object.values(groupedEntries).forEach(entries => {
        entries.forEach(entry => {
          promises.push(
            this.getExchangeRateOn({
              date: entry.date,
              currency: activeCurrencyWithSubunit
            }).then(rate => {
              series.push([
                entry.date,
                entry.netWorth * rate,
                sumBy(entry.assets, ({ amount, baseRate }) =>
                  baseRate ? amount / baseRate : 0
                ) * rate,
                sumBy(entry.liabilities, ({ amount, baseRate }) =>
                  baseRate ? amount / baseRate : 0
                ) * rate
              ])
            })
          )
        })
      })
      await Promise.all(promises)
      // Generate the regression
      const sortedData = sortBy(series, ([x]) => x)
      return sortedData
    },
    async basicProjectionData() {
      this.isUpdating = true
      const {
        method,
        firstDate,
        lastDate,
        sourceData,
        activeCurrencyWithSubunit
      } = this
      if (!sourceData) {
        return null
      }
      const datasets = []
      // Plot the last 12 real data points
      const promises = Object.values(this.groupedEntries).map(async entries => {
        const { date, netWorth } = last(entries)
        const rate = await this.getExchangeRateOn({
          date,
          currency: activeCurrencyWithSubunit
        })
        return {
          t: dayjs(date)
            .startOf('month')
            .toDate(),
          y: netWorth * rate
        }
      })
      await Promise.all(promises).then(data => {
        datasets.push({
          label: 'Actual',
          color: '#43ae43',
          data
        })
      })
      // Plot the next 12 months of predicted data points
      const pointsToPredict = []
      let curDate
      // Can set to firstDate to show fit
      curDate = lastDate.add(1, 'month')
      for (
        ;
        curDate.diff(lastDate, 'months') < 12;
        curDate = curDate.add(1, 'month')
      ) {
        pointsToPredict.push(curDate.toDate())
      }
      const predictedData = await basicProjection({
        type: method,
        realData: sourceData,
        firstDate: firstDate.toDate(),
        dates: pointsToPredict
      }).then(data =>
        data.map((yy, idx) => ({
          t: pointsToPredict[idx],
          y: yy
        }))
      )
      predictedData.unshift(last(datasets[0].data))
      datasets.unshift({
        label: 'Predicted',
        color: 'rgba(0,112,255,.5)',
        data: predictedData
      })
      this.isUpdating = false
      return datasets
    }
  },
  watch: {
    isUpdating: {
      immediate: true,
      handler(val) {
        if (val) this.$parent.$emit('update:start')
        else this.$parent.$emit('update:done')
      }
    }
  },
  methods: {
    ...mapActions('currencies', ['getExchangeRateOn'])
  }
}
</script>
