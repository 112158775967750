<template lang="pug">
.simulator-config
  .asset-configuration(v-if="assets.length")
    h3 Assets
    simulator-holding-config.asset(v-model="assets[index]" v-for="(asset, index) in assets" :key="asset.id" :assets="assets" :liabilities="liabilities")
  .liability-configuration(v-if="liabilities.length")
    h3 Liabilities
    simulator-holding-config.liability(v-model="liabilities[index]" v-for="(liability, index) in liabilities" :key="liability.id" :assets="assets" :liabilities="liabilities")
  .save-button
    b-button.is-large.is-primary(:disabled="!formIsValid" @click="saveChanges" :loading="isSaving")
      | Save changes
    span.hint(v-if="!formIsValid") Fill in the required fields (underlined red) to continue
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { pick, cloneDeep, sortBy } from 'lodash'

import SimulatorHoldingConfig from '@/components/simulator/HoldingConfig.vue'

export default {
  name: 'SimulatorConfig',
  components: {
    SimulatorHoldingConfig
  },
  data() {
    return {
      assets: [],
      liabilities: [],
      isSaving: false
    }
  },
  computed: {
    ...mapState('entries', ['entries']),
    ...mapGetters('categories', ['getCategoryById', 'categoryWeight']),
    lastEntry() {
      return this.entries[0]
    },
    formIsValid() {
      const { assets = [], liabilities = [] } = this
      let allDataPresent = true
      let atLeastOneConfigured = false
      assets.concat(liabilities).forEach(holding => {
        if (!holding.includeInAnalysis) {
          return
        }
        atLeastOneConfigured = true
        holding.flatChanges.forEach(({ amount }) => {
          if (!amount) allDataPresent = false
        })
      })
      return allDataPresent && atLeastOneConfigured
    }
  },
  watch: {
    lastEntry: {
      immediate: true,
      handler(lastEntry) {
        const assets = []
        lastEntry.assets.forEach(asset => {
          const category = this.getCategoryById(asset.categoryId)
          assets.push({
            id: category.id,
            name: category.name,
            currency: asset.currency,
            subunit: asset.subunit,
            isAsset: true,
            ...this.holdingFormFor(category, asset)
          })
        })
        const liabilities = []
        lastEntry.liabilities.forEach(liability => {
          const category = this.getCategoryById(liability.categoryId)
          liabilities.push({
            id: category.id,
            name: category.name,
            currency: liability.currency,
            subunit: liability.subunit,
            isAsset: false,
            ...this.holdingFormFor(category, liability)
          })
        })
        this.assets = sortBy(assets, ({ id }) => this.categoryWeight(id))
        this.liabilities = sortBy(liabilities, ({ id }) =>
          this.categoryWeight(id)
        )
      }
    }
  },
  methods: {
    ...mapActions('categories', ['saveProjectionData']),
    holdingFormFor(category, holding) {
      return {
        includeInAnalysis: !!holding.amount,
        percentageChange: {
          sign: 'increase',
          period: 'month',
          amount: '0'
        },
        flatChanges: [],
        hasCap: false,
        cappedAmount: null,
        snowballCategoryId: null,
        payInterestFrom: null,
        ...cloneDeep(
          pick(category, [
            'includeInAnalysis',
            'percentageChange',
            'flatChanges',
            'offsetLoanId',
            'snowballCategoryId',
            'payInterestFrom',
            'hasCap',
            'cappedAmount',
            'configCurrency'
          ])
        )
      }
    },
    async saveChanges() {
      this.isSaving = true
      const { assets, liabilities } = this
      const changes = assets
        .concat(liabilities)
        .map(holding =>
          pick(holding, [
            'id',
            'includeInAnalysis',
            'percentageChange',
            'flatChanges',
            'snowballCategoryId',
            'payInterestFrom',
            'hasCap',
            'cappedAmount',
            'configCurrency'
          ])
        )
        .map(cloneDeep)
      changes.forEach(change => {
        if (change.includeInAnalysis) {
          change.configCurrency = this.$store.getters[
            'categories/getCategoryLastCurrency'
          ](change)
        }
      })
      await this.saveProjectionData(changes)
      this.isSaving = false
      this.$emit('saved')
    }
  }
}
</script>

<style lang="sass" scoped>
h3
  font-size: 1.1rem
  margin: 1.5rem 0 0.75rem 0
.asset, .liability
  margin-bottom: 1.5rem
.save-button
  display: flex
  align-items: center
  .hint
    margin-left: 0.5rem
</style>
