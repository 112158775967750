<template lang="pug">
.holding-config
  b-checkbox.holding-name(v-model="value.includeInAnalysis")
    | {{ value.name }}
    span(v-if="!value.includeInAnalysis") &nbsp;(excluded)
  .currency-change-warning(v-if="showCurrencyPrompt")
    b-icon(icon="exclamation-circle")
    <strong>Warning:</strong> currency or unit has changed. Review amounts below and save to continue
  .sentences(v-if="value.includeInAnalysis")
    .sentence.ml-1(v-if="offsetLoan")
      span.mt-1 This is an offset account for {{ offsetLoan.name }} and will not accrue interest
    .sentence.ml-1(v-if="!offsetLoan")
      textual-toggle.ml-0(v-model="value.percentageChange.sign" :options="signOptions" v-if="value.isAsset")
      textual-toggle.ml-0(v-model="value.percentageChange.sign" :options="inverseSignOptions" v-if="!value.isAsset")
      span at an average rate of
      textual-percent(v-model="value.percentageChange.amount")
      span per year, {{ value.isAsset ? 'credited' : 'charged' }}
      textual-select(v-model="value.percentageChange.period" :options='periodOptions')
    .sentence.mt-2(v-if="!value.isAsset && value.percentageChange.sign == 'increase'")
      span Charge interest to
      textual-select(v-model="value.payInterestFrom" :options="otherCategories")
        option(:value='null') {{ value.name }}
    .sentence.mt-2(v-for="(change, index) in value.flatChanges")
      a.remove-button(type="is-danger" @click="rmFlatChange(index)" title="Remove")
        b-icon(icon="minus-square" size="is-small")
      textual-toggle(v-model="change.sign" :options="signOptions" v-if="value.isAsset")
      textual-toggle(v-model="change.sign" :options="inverseSignOptions" v-if="!value.isAsset")
      span by
      textual-currency(v-model="change.amount" :currency="value.currency" :subunit="value.subunit")
      textual-select(v-model="change.period" :options='periodOptions')
      textual-toggle(v-model="change.indexation" :options='indexOptions')
      span(v-if="change.indexation !== 'none'") by
      textual-percent(v-if="change.indexation !== 'none'" v-model="change.indexFactor")
      textual-select(v-if="change.indexation !== 'none'" v-model="change.indexPeriod" :options="periodOptions")
    .sentence.mt-2(v-if="value.hasCap")
      a.remove-button(type="is-danger" @click="rmCap()" title="Remove")
        b-icon(icon="minus-square" size="is-small")
      span Cap the value to a maximum of
      textual-currency(v-model="value.cappedAmount" :currency="value.currency" :subunit="value.subunit")
    .sentence.mt-2(v-if="offsetLoan || value.hasCap")
      span
        | If
        span.clause-padding(v-if="offsetLoan") fully offset
        span.clause-padding(v-if="offsetLoan && value.hasCap") or
        span.clause-padding(v-if="value.hasCap") capped
        | put the extra money into
      textual-select(v-model="value.snowballCategoryId" :options="otherCategories")
        option(:value='null') {{ value.isAsset ? value.name : 'Nothing' }}
    .sentence.mt-2(v-if="holdingWillBePaidOff && otherCategories.length > 1")
      span If paid off, put the extra money into
      textual-select(v-model="value.snowballCategoryId" :options="otherCategories")
        option(:value='null') {{ value.isAsset ? value.name : 'Nothing' }}
    a.sentence.mt-4(@click="addFlatChange()")
      .add-button
        b-icon(icon="plus-square" size="is-small")
      .ghost-text
        | Add {{value.flatChanges.length ? 'another' : 'a'}} flat rate change
    a.sentence.mt-1(@click="addCap()" v-if="value.isAsset && !value.hasCap")
      .add-button
        b-icon(icon="plus-square" size="is-small")
      .ghost-text
        | Add a cap

</template>
<script>
import { find, cloneDeep } from 'lodash'

import TextualSelect from '@/components/textual/Select.vue'
import TextualToggle from '@/components/textual/Toggle.vue'
import TextualPercent from '@/components/textual/Percent.vue'
import TextualCurrency from '@/components/textual/Currency.vue'

const periodOptions = [
  { value: 'day', name: 'daily' },
  { value: 'week', name: 'weekly' },
  { value: 'fortnight', name: 'fortnightly' },
  { value: 'month', name: 'monthly' },
  { value: 'quarter', name: 'quarterly' },
  { value: 'year', name: 'yearly' }
]

const signOptions = [
  { value: 'increase', name: 'Increase', color: '#43ae43' },
  { value: 'decrease', name: 'Decrease', color: '#b31e1e' }
]

const inverseSignOptions = [
  { value: 'increase', name: 'Increase', color: '#b31e1e' },
  { value: 'decrease', name: 'Decrease', color: '#43ae43' }
]

const indexOptions = [
  { value: 'none', name: 'forever', color: '#333' },
  { value: 'increase', name: 'growing', color: '#333' },
  { value: 'decrease', name: 'shrinking', color: '#333' }
]

export default {
  components: {
    TextualSelect,
    TextualToggle,
    TextualPercent,
    TextualCurrency
  },
  props: {
    value: Object,
    assets: Array,
    liabilities: Array
  },
  data() {
    return {
      periodOptions,
      signOptions,
      inverseSignOptions,
      indexOptions,
      showCurrencyPrompt: false
    }
  },
  computed: {
    holdingWillBePaidOff() {
      const { value } = this
      return !value.isAsset
    },
    otherCategories() {
      const { assets, liabilities, value } = this
      if (assets instanceof Array && liabilities instanceof Array) {
        const options = []
        assets
          .concat(liabilities)
          .forEach(({ id, name, includeInAnalysis }) => {
            if (id !== value.id && includeInAnalysis) {
              options.push({ value: id, name })
            }
          })
        return options
      }
      return null
    },
    offsetLoan() {
      const { liabilities, value } = this
      const { offsetLoanId } = value
      if (!offsetLoanId) {
        return null
      }
      if (liabilities instanceof Array) {
        return liabilities.find(({ id }) => id === offsetLoanId)
      }
      return null
    }
  },
  watch: {
    'value.includeInAnalysis': {
      immediate: true,
      handler() {
        this.showCurrencyPrompt = this.needsCurrencyPrompt()
      }
    },
    otherCategories: {
      immediate: true,
      handler(categories) {
        const { value } = this
        if (categories === null) {
          return
        }
        const refFields = ['snowballCategoryId', 'payInterestFrom']
        const holding = cloneDeep(value)
        let hasChanged = false
        refFields.forEach(ref => {
          if (value[ref]) {
            const category = find(
              categories,
              ({ value: holdingId }) => holdingId === value[ref]
            )
            if (!category) {
              holding[ref] = null
              hasChanged = true
            }
          }
        })
        if (hasChanged) {
          this.$emit('input', holding)
        }
      }
    },
    'value.percentageChange.amount': {
      handler(amount) {
        const { value, offsetLoan, assets } = this
        if (offsetLoan) {
          offsetLoan.percentageChange.amount = amount
        }
        assets.forEach(asset => {
          if (asset.offsetLoanId === value.id) {
            asset.percentageChange.amount = amount
          }
        })
      }
    }
  },
  methods: {
    addFlatChange() {
      const holding = cloneDeep(this.value)
      holding.flatChanges.push({
        sign: 'increase',
        period: 'month',
        amount: 0,
        indexation: 'none',
        indexFactor: '0',
        indexPeriod: 'year'
      })
      this.$emit('input', holding)
    },
    needsCurrencyPrompt() {
      const {
        includeInAnalysis,
        configCurrency,
        flatChanges,
        hasCap,
        currency,
        subunit
      } = this.value
      // Disabled = ignore
      if (!includeInAnalysis) {
        return false
      }
      // No cached currency = sync then ignore
      if (!configCurrency) {
        this.syncCurrencyInfo()
        return false
      }
      // No currency denominated changes = ignore
      if (!flatChanges.length && !hasCap) {
        return false
      }
      // Wrong currency
      if (currency !== configCurrency.currency) {
        return true
      }
      // Wrong unit
      if (subunit !== configCurrency.subunit) {
        return true
      }
      // All good
      return false
    },
    syncCurrencyInfo() {
      const { currency, subunit, configCurrency } = this.value
      if (
        !configCurrency ||
        currency !== configCurrency.currency ||
        subunit !== configCurrency.subunit
      ) {
        this.$emit('input', {
          ...cloneDeep(this.value),
          configCurrency: {
            currency,
            subunit
          }
        })
      }
    },
    addCap() {
      const holding = cloneDeep(this.value)
      holding.hasCap = true
      holding.cappedAmount = null
      this.$emit('input', holding)
    },
    rmCap() {
      const holding = cloneDeep(this.value)
      holding.hasCap = false
      holding.cappedAmount = null
      this.$emit('input', holding)
    },
    rmFlatChange(idx) {
      const holding = cloneDeep(this.value)
      holding.flatChanges.splice(idx, 1)
      this.$emit('input', holding)
    }
  }
}
</script>

<style lang="sass" scoped>
.sentence
  display: flex
  flex-wrap: wrap
  align-items: center
  position: relative
  > *
    margin-bottom: 0.5rem
.holding-name
  font-weight: bold
  color: #444
.sentences
  padding-left: 1.5rem
.add-button, .remove-button
  display: flex
  align-items: center
  position: absolute
  left: -1.25rem
  top: 0.25rem
  color: #bbb
a:hover .add-button, .add-button:hover
  color: green
.remove-button
  top: 0.4rem
  &:hover
    color: red
.ghost-text
  cursor: pointer
  display: block
  padding-left: 0.25rem
  color: #999
a:hover .ghost-text, .ghost-text:hover
  color: #333
.clause-padding
  padding: 0 0.25rem
  + .clause-padding
    padding-left: 0
::v-deep .currency-input:not(:focus)
  &.zero, &.empty
    border-color: red
.currency-change-warning
  font-size: .9rem
  color: #333
  margin: .5rem 0 .75rem 0
  position: relative
  padding-left: 1.75rem
  strong
    color: red
  .icon
    color: red
    left: -.1rem
    position: absolute
</style>
