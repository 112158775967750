<template lang="pug">
b-field.responsive-buttonset
  .control(v-for="option in options")
    b-button(:class="value === option.value && 'is-primary'" @click="$emit('input', option.value)")
      | {{ option.name }}
</template>

<script>
export default {
  props: {
    options: Array,
    value: null
  }
}
</script>
<style scoped lang="sass">
.responsive-buttonset
  flex-wrap: wrap
</style>
