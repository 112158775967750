<template lang="pug">
a.textual-toggle(@click="nextState()" tabindex="0" :style="'color: '+(activeState.color || 'inherit')")
  | {{ activeState.name }}
</template>
<script>
import { find } from 'lodash'

export default {
  name: 'TextualToggle',
  props: {
    value: String,
    options: Array
  },
  computed: {
    activeState() {
      return find(this.options, ({ value }) => value === this.value)
    }
  },
  methods: {
    nextState() {
      let idx = this.options.indexOf(this.activeState)
      idx += 1
      if (idx >= this.options.length) {
        idx = 0
      }
      this.$emit('input', this.options[idx].value)
    }
  }
}
</script>

<style lang="sass" scoped>
a
  margin: 0 0.375rem
  border-bottom: 2px solid #aaa
  font-weight: bold
  position: relative
  top: 1px
  padding-top: 1px
  &:focus
    outline: none
    border-color: #333
</style>
