<script>
import { formatDate } from '@/misc/filters'
import currencyFormatter from '@/misc/currencyFormatter'
import { Line } from '@/vue-chartjs'

export default {
  extends: Line,
  props: {
    datasets: Array,
    currency: String,
    granularity: String,
    label: String,
    color: String,
    fill: Boolean
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              time: {
                displayFormats: {
                  millisecond: 'MMM D',
                  second: 'MMM D',
                  minute: 'MMM D',
                  hour: 'MMM D'
                }
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                callback: value => currencyFormatter(value, this.currency)
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            title: ([item], { datasets }) => {
              const dataset = datasets[item.datasetIndex]
              const point = dataset.data[item.index]
              return formatDate(point.t, this.granularity)
            },
            label: item => currencyFormatter(item.yLabel, this.currency)
          }
        }
      }
    }
  },
  watch: {
    datasets: {
      handler() {
        this.doRender()
      }
    }
  },
  mounted() {
    this.doRender()
  },
  methods: {
    doRender() {
      if (!this.datasets) return
      // Update the granularity on the x axis settings
      this.options.scales.xAxes[0].time.unit = this.granularity
      this.renderChart(
        {
          datasets: this.datasets.map(
            ({ label, color, data, fill = false }) => ({
              label,
              backgroundColor: color,
              data,
              fill,
              borderColor: fill ? 'rgba(0,0,0,.1)' : color
            })
          )
        },
        this.options
      )
    }
  }
}
</script>
