<template lang="pug">
b-modal(v-model.sync="showModal" has-modal-card trap-focus aria-role="dialog" aria-modal)
  .modal-card(v-if="scenario")
    header.modal-card-head
      b-input.modal-card-title(v-model="scenario.name" placeholder="Name your scenario..." ref="scenarioName")
      b-button(icon-left="times" @click="hide")
    section.modal-card-body
      .actions-form
        .actions(v-if="scenario.actions.length")
          action-form(v-for="(action, index) in scenario.actions" v-model="scenario.actions[index]" @removed="removeAction(action)" :key="index")
        .action-adder
          span.add-buttons
            strong Add:
            b-button(@click="addAction('once')") One-off change
            b-button(@click="addAction('repeating')") Repeating change
            //b-button(@click="addAction('changeSetup')") Change setup
        .action-help
          .help-text.content(v-if="showHelpText")
            p
              | <strong>A one-off change</strong> happens once on the day you specify. It can be used to add or
              | subtract a fixed amount from holdings. Use it to plan for large, sudden changes
              | in the future like selling a house or rebalancing your assets.
            p
              | <strong>Repeating changes</strong> are set $ value changes to holdings that happen either daily,
              | weekly or monthly. They can have a start and a finish. Use them to plan for expenses
              | like putting your kids through school for 5 years.
            //p
              | The last option is to change the parameters of the simulation entirely for a holding
              | This lets you change the simulator setup of that holding from a point in time onwards.
          a.help-text-toggle(@click="showHelpText = !showHelpText")
            span(v-if="!showHelpText") What does this mean?
            span(v-if="showHelpText") Hide help text
      b-message(v-if="error" type="is-warning") Error: {{ error }}
    footer.modal-card-foot
      button.button.is-danger(@click="remove" v-if="!isNew") Delete
      .flex-grow
      button.button.is-primary(@click="save" :disabled="!valid") Save
</template>

<script>
import { cloneDeep, every } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import ActionForm from './ActionForm'

const blankScenario = () => {
  return {
    name: '',
    actions: []
  }
}

const blankAction = type => {
  const action = {
    type,
    occurs: null
  }
  switch (type) {
    case 'once':
      action.changes = []
      break
    case 'repeating':
      action.changes = []
      action.finishes = null
      action.period = 'month'
      break
    case 'changeSetup':
      action.finishes = null
      action.holdingId = null
      action.config = null
      break
    default:
      throw new Error(`Invalid action ${type}`)
  }
  return action
}

const changeIsValid = change => {
  switch (change.type) {
    case 'transfer':
      return change.holdingId && change.destId
    case 'add':
      return change.holdingId
    default:
      return true
  }
}

const actionIsValid = action => {
  if (action.type === 'once' && !action.occurs) {
    return false
  }
  return action.changes.length && every(action.changes, changeIsValid)
}

export default {
  components: {
    ActionForm
  },
  data() {
    return {
      showHelpText: false,
      showModal: false,
      scenario: null,
      isNew: false,
      isLoading: false,
      error: null
    }
  },
  computed: {
    ...mapGetters('scenarios', ['getChangeCurrency']),
    valid() {
      if (this.scenario) {
        const { name, actions } = this.scenario
        return name && actions.length && every(actions, actionIsValid)
      }
      return false
    }
  },
  methods: {
    ...mapActions('scenarios', [
      'createScenario',
      'updateScenario',
      'deleteScenario'
    ]),
    newScenario() {
      this.scenario = blankScenario()
      this.isNew = true
      this.showModal = true
      this.$nextTick(() => {
        this.$refs.scenarioName.focus()
      })
    },
    editScenario(scenario) {
      this.scenario = cloneDeep(scenario)
      this.isNew = false
      this.showModal = true
    },
    hide() {
      this.showModal = false
      this.scenario = null
    },
    syncCurrencyInfo() {
      this.scenario.actions.forEach(action => {
        action.changes.forEach(change => {
          change.configCurrency = this.getChangeCurrency(change)
        })
      })
    },
    async save() {
      this.isLoading = true
      this.syncCurrencyInfo()
      try {
        if (this.isNew) {
          await this.createScenario(this.scenario)
          this.$buefy.toast.open('Scenario added!')
          this.hide()
        } else {
          await this.updateScenario(this.scenario)
          this.$buefy.toast.open('Scenario updated!')
          this.hide()
        }
      } catch (error) {
        this.error = error.message
      }
      this.isLoading = false
    },
    async remove() {
      this.isLoading = true
      try {
        await this.deleteScenario(this.scenario.id)
        this.$buefy.toast.open('Scenario removed!')
        this.hide()
      } catch (error) {
        this.error = error.message
      }
      this.isLoading = false
    },
    addAction(type) {
      this.scenario.actions.push(blankAction(type))
    },
    removeAction(action) {
      const idx = this.scenario.actions.indexOf(action)
      if (~idx) {
        this.scenario.actions.splice(idx, 1)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.modal-card-head
  padding: 1rem 0.75rem
.modal-card-foot
  display: flex
  justify-content: space-between
.flex-grow
  flex-grow: 1
.actions
  margin-top: -1.25rem
  margin-left: -1.25rem
  margin-right: -1.25rem
  margin-bottom: 0.5rem
.modal-card-body
  background: #dadada
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 4px 0 rgba(0, 0, 0, 0.06)
.action-adder
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: center
  strong
    display: none
  @media screen and (min-width: 450px)
    strong
      display: block
      position: absolute
      left: -3rem
      top: 0
      bottom: 0
      display: flex
      align-items: center
      justify-content: center
  .add-buttons
    padding-top: 0.5rem
    display: flex
    flex-wrap: wrap
    margin: -0.25rem
    padding-bottom: 0.5rem
    position: relative
  .button
    margin: 0.25rem
    width: 150px
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
.action-help
  display: flex
  flex-direction: column
  align-items: center
.help-text-toggle
  font-size: 0.9rem
  display: inline-block
  padding: 0.25rem 0.5rem 0.5rem
.help-text
  background: white
  padding: 0.75rem 1rem
  border-radius: .5rem
  margin: 0.5rem -.5rem 1rem
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
.modal-card-title
  margin-right: .75rem
  ::v-deep .icon
    right: 0.5rem !important
    top: 0.5rem
    height: 1.5rem
    width: 1.5rem
</style>
