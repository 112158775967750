<template lang="pug">
span.diff-span(:class="{'is-positive': roundedValue > 0, 'is-negative': roundedValue < 0}")
  span(v-if="roundedValue") {{ roundedValue | currencyFormatter(currency) }}
  span(v-else) -
</template>

<script>
import { round } from 'lodash'
import currencyFormatter from '@/misc/currencyFormatter'

export default {
  filters: {
    currencyFormatter
  },
  props: {
    value: Number,
    currency: String
  },
  computed: {
    roundedValue() {
      return round(this.value, 8) + 0
    }
  }
}
</script>

<style lang="sass" scoped>
.is-positive
  color: green
  &:before
    content: "+"
.is-negative
  color: red
</style>
