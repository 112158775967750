<template lang="pug">
currency-input.currency-input(:value="input" :currency="currencyOption" :precision="currencyPrecision" @input="update" :placeholder="formattedZero" :class='inputClass' :allow-negative="allowNegative" ref="currencyInput")
</template>
<script>
import subunits from '@/mixins/subunits'
import currencyFormatter from '@/misc/currencyFormatter'
import { currencyWithSubunit } from '@/misc/helpers'

export default {
  name: 'TextualCurrency',
  mixins: [subunits],
  props: {
    value: Number,
    currency: String,
    subunit: String,
    allowNegative: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    chosenCurrency() {
      return this.$store.getters['currencies/getCurrencyById'](this.currency)
    },
    inputClass() {
      const { value } = this
      if (value) {
        return 'present'
      }
      if (value === 0) {
        return 'zero'
      }
      return 'empty'
    },
    formattedZero() {
      const { currency, subunit } = this
      return currencyFormatter(0, currencyWithSubunit(currency, subunit))
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.input = value
        this.resizeInput()
        // A second resize is needed for when Vue swaps out the component
        this.$nextTick(this.resizeInput)
      }
    },
    formattedZero() {
      this.$nextTick(this.resizeInput)
    }
  },
  mounted() {
    const { $el } = this.$refs.currencyInput
    $el.addEventListener('change', this.resizeInput)
    $el.addEventListener('keyup', this.resizeInput)
    $el.addEventListener('blur', this.resizeInput)
    this.resizeInput()
  },
  methods: {
    update(value) {
      this.$emit('input', value)
    },
    resizeInput() {
      const { formattedZero } = this
      if (!this.$refs.currencyInput) {
        return
      }
      const { $el } = this.$refs.currencyInput
      const length = Math.max(4, ($el.value || formattedZero).length + 1)
      $el.style.width = `${length}ch`
    }
  }
}
</script>

<style lang="sass" scoped>
.currency-input
  margin: 0 0.375rem
  font-family: monospace
  position: relative
  top: 2px
  border: none
  border-bottom: 2px solid #aaa
  font-weight: bold
  font-size: 1rem
  background: none
  padding: 0
  padding-bottom: 1px
  &:focus
    outline: none
    border-color: #333
</style>
