import { render, staticRenderFns } from "./Currency.vue?vue&type=template&id=a2a27faa&scoped=true&lang=pug&"
import script from "./Currency.vue?vue&type=script&lang=js&"
export * from "./Currency.vue?vue&type=script&lang=js&"
import style0 from "./Currency.vue?vue&type=style&index=0&id=a2a27faa&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2a27faa",
  null
  
)

export default component.exports