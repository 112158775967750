<template lang="pug">
.action-form
  .action-heading
    textual-text-area.action-title(v-model="value.name" placeholder="Add an optional description...")
    b-button.is-small(icon-left="times" @click="$emit('removed')")
  template(v-if="value.type === 'once'")
    .sentences
      .sentence.action-params
        span.ml-1 On
        textual-datepicker(v-model="value.occurs" placeholder="date" :min-date="minDate")
        |:
        b-button.is-small.mx-1.ml-2(@click="addAddition()") Add/subtract
        b-button.is-small.mx-1(@click="addTransfer()") Transfer
      .sentence.change(v-for="change in value.changes")
        a.remove-button.delete(@click="removeChange(change)")
        template(v-if="change.type == 'transfer'")
          span.ml-1 Transfer a<span v-if="change.operation=='flat'">n</span>
          textual-toggle(v-model="change.operation" :options="flatOrPercent")
          span of
          textual-currency(v-if="change.operation == 'flat'" :value="change.amount" @input="updateChangeAmount(change, $event)" v-bind="currencyFor(change)")
          textual-percent(v-if="change.operation == 'percent'" v-model="change.percentAmount")
          span from
          textual-select(:value="change.holdingId" :options="holdingOptions" @input="updateChangeHolding(change, $event)")
          span to
          textual-select(v-model="change.destId" :options="holdingOptions")
        template(v-if="change.type == 'add'")
          textual-toggle(v-model="change.sign" :options="signOptions")
          textual-select(:value="change.holdingId" :options="holdingOptions" @input="updateChangeHolding(change, $event)")
          span by a
          textual-toggle(v-model="change.operation" :options="flatOrPercent")
          span of
          textual-currency(v-if="change.operation == 'flat'" :value="change.amount" @input="updateChangeAmount(change, $event)" v-bind="currencyFor(change)")
          textual-percent(v-if="change.operation == 'percent'" v-model="change.percentAmount")
        .break(v-if="unitHasChanged(change)")
        .unit-change-warning(v-if="unitHasChanged(change)")
          b-icon(icon="exclamation-circle")
          | <strong>Warning:</strong> currency or unit has changed. Save to clear warning
  template(v-else-if="value.type === 'repeating'")
    .sentences
      .sentence.action-params
        textual-select(v-model="value.period" :options="periodOptions")
        span.ml-1 from
        textual-datepicker(v-model="value.occurs" placeholder="now" :min-date="minDate")
        span until
        textual-datepicker(v-model="value.finishes" placeholder="forever" :min-date="minDate")
        |:
        b-button.is-small.mx-1.ml-2(@click="addAddition()") Add/subtract
        b-button.is-small.mx-1(@click="addTransfer()") Transfer
      .sentence.change(v-for="change in value.changes")
        a.remove-button.delete(@click="removeChange(change)")
        template(v-if="change.type == 'transfer'")
          span.ml-1 Transfer a
          textual-toggle(v-model="change.indexation" :options='indexOptions' v-if="change.operation == 'flat'")
          textual-toggle(:value="change.operation" @input="updateOperation(change, $event)" :options="flatOrPercent")
          span of
          textual-currency(v-if="change.operation == 'flat'" :value="change.amount" @input="updateChangeAmount(change, $event)" v-bind="currencyFor(change)")
          textual-percent(v-if="change.operation == 'percent'" v-model="change.percentAmount")
          span from
          textual-select(:value="change.holdingId" :options="holdingOptions" @input="updateChangeHolding(change, $event)")
          span to
          textual-select(v-model="change.destId" :options="holdingOptions")
        template(v-if="change.type == 'add'")
          textual-toggle(v-model="change.sign" :options="signOptions")
          textual-select(:value="change.holdingId" :options="holdingOptions" @input="updateChangeHolding(change, $event)")
          span by a
          textual-toggle(v-model="change.indexation" :options='indexOptions' v-if="change.operation == 'flat'")
          textual-toggle(:value="change.operation" @input="updateOperation(change, $event)" :options="flatOrPercent")
          span of
          textual-currency(v-if="change.operation == 'flat'" :value="change.amount" @input="updateChangeAmount(change, $event)" v-bind="currencyFor(change)")
          textual-percent(v-if="change.operation == 'percent'" v-model="change.percentAmount")
        .break(v-if="change.indexation !== 'none'")
        span.ml-1(v-if="change.indexation == 'increase'") Grow the amount by
        span.ml-1(v-if="change.indexation == 'decrease'") Shrink the amount by
        textual-percent(v-if="change.indexation !== 'none'" v-model="change.indexFactor")
        textual-select(v-if="change.indexation !== 'none'" v-model="change.indexPeriod" :options="periodOptions")
        .break(v-if="unitHasChanged(change)")
        .unit-change-warning(v-if="unitHasChanged(change)")
          b-icon(icon="exclamation-circle")
          | <strong>Warning:</strong> currency or unit has changed. Save to clear warning
  template(v-else-if="value.type === 'changeSetup'")
    p TODO
</template>
<script>
import { cloneDeep } from 'lodash'
import { mapGetters, mapState } from 'vuex'
import dayjs from '@/dayjs'

import TextualTextArea from '@/components/textual/TextArea.vue'
import TextualDatepicker from '@/components/textual/Datepicker.vue'
import TextualToggle from '@/components/textual/Toggle.vue'
import TextualSelect from '@/components/textual/Select.vue'
import TextualPercent from '@/components/textual/Percent.vue'
import TextualCurrency from '@/components/textual/Currency.vue'

const flatOrPercent = [
  { name: 'amount', value: 'flat', color: '#333333' },
  { name: 'percentage', value: 'percent', color: '#333333' }
]
const signOptions = [
  { name: 'Increase', value: 'increase', color: '#43ae43' },
  { name: 'Decrease', value: 'decrease', color: '#b31e1e' }
]
const periodOptions = [
  { value: 'day', name: 'Daily' },
  { value: 'week', name: 'Weekly' },
  { value: 'fortnight', name: 'Fortnightly' },
  { value: 'month', name: 'Monthly' },
  { value: 'quarter', name: 'Quarterly' },
  { value: 'year', name: 'Yearly' }
]
const indexOptions = [
  { value: 'none', name: 'flat', color: '#333' },
  { value: 'increase', name: 'growing', color: '#333' },
  { value: 'decrease', name: 'shrinking', color: '#333' }
]

export default {
  components: {
    TextualTextArea,
    TextualDatepicker,
    TextualToggle,
    TextualSelect,
    TextualPercent,
    TextualCurrency
  },
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      flatOrPercent,
      signOptions,
      periodOptions,
      indexOptions
    }
  },
  computed: {
    ...mapState('entries', ['entries']),
    ...mapState('currencies', ['activeCurrency']),
    ...mapGetters('categories', ['getCategoryById']),
    ...mapGetters('scenarios', ['getChangeCurrency']),
    ...mapGetters('currencies', ['activeSubunit']),

    minDate() {
      if (this.entries && this.entries[0]) {
        return this.entries[0].date
      }
      // Default to today
      return dayjs()
        .startOf('day')
        .toDate()
    },

    holdingOptions() {
      const lastEntry = this.entries[0]
      const options = []
      lastEntry.assets.forEach(asset => {
        const category = this.getCategoryById(asset.categoryId)
        if (!category.includeInAnalysis) {
          return
        }
        options.push({
          value: category.id,
          name: category.name
        })
      })
      lastEntry.liabilities.forEach(liability => {
        const category = this.getCategoryById(liability.categoryId)
        if (!category.includeInAnalysis) {
          return
        }
        options.push({
          value: category.id,
          name: category.name
        })
      })
      return options
    }
  },
  methods: {
    currencyFor(change) {
      const { currency, subunit } = this.getChangeCurrency(change)
      const { activeCurrency, activeSubunit } = this
      if (currency) {
        return { currency, subunit }
      }
      return {
        currency: activeCurrency.id,
        subunit: activeSubunit ? activeSubunit.id : null
      }
    },
    unitHasChanged(change) {
      const currencyInfo = this.getChangeCurrency(change)
      const { holdingId } = change
      if (holdingId === null) {
        return false
      }
      const { currency, subunit } = change.configCurrency
      return !(
        currency === currencyInfo.currency && subunit === currencyInfo.subunit
      )
    },
    updateChangeHolding(change, holdingId) {
      change.holdingId = holdingId
      change.configCurrency = this.getChangeCurrency(change)
    },
    updateChangeAmount(change, amount) {
      change.amount = amount
      change.configCurrency = this.getChangeCurrency(change)
    },
    addTransfer() {
      const action = cloneDeep(this.value)
      action.changes.push({
        type: 'transfer',
        operation: 'flat',
        holdingId: null,
        configCurrency: {
          currency: null,
          subunit: null
        },
        destId: null,
        amount: 0,
        percentAmount: '0',
        indexation: 'none',
        indexFactor: '0',
        indexPeriod: 'year'
      })
      this.$emit('input', action)
    },
    addAddition() {
      const action = cloneDeep(this.value)
      action.changes.push({
        type: 'add',
        operation: 'flat',
        sign: 'increase',
        holdingId: null,
        configCurrency: {
          currency: null,
          subunit: null
        },
        amount: 0,
        percentAmount: '0',
        indexation: 'none',
        indexFactor: '0',
        indexPeriod: 'year'
      })
      this.$emit('input', action)
    },
    updateOperation(change, operation) {
      change.operation = operation
      if (change.operation === 'percent') {
        change.indexation = 'none'
      }
    },
    removeChange(change) {
      const action = cloneDeep(this.value)
      const idx = this.value.changes.indexOf(change)
      if (~idx) {
        action.changes.splice(idx, 1)
        this.$emit('input', action)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.action-form
  margin: 0.5rem
  border-radius: 0.5rem
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
.action-heading
  display: flex
  align-items: flex-start
  padding: 0.5rem
  border-bottom: 1px solid #ddd
  background: #43ae43
  border-radius: .5rem .5rem 0 0
  .action-title
    border: none
    border-radius: .35rem
    flex-grow: 1
    color: white
    font-weight: bold
    &::placeholder
      opacity: .65
      color: white
      font-weight: bold
  button
    display: flex
    align-items: center
    justify-content: center
    margin-top: .25rem
    margin-right: .25rem
    margin-left: 1rem
    border-radius: 50%
    &:hover
      color: red
.flex-cols
  display: flex
  margin: -0.5rem
.flex-2 > *
  flex-grow: 1
  flex-basis: 50%
  margin: 0.5rem
.sentence
  background: white
  display: flex
  flex-wrap: wrap
  align-items: center
  position: relative
  > *
    margin-bottom: 0.5rem
  .break
    flex-basis: 100%
    height: 0
    margin-bottom: .25rem
.action-params
  padding: 0.65rem .75rem .5rem
  &:last-child
    border-radius: 0 0 .5rem .5rem
.change
  background: #fafafa
  border-top: 1px solid #ddd
  padding: 0.5rem .5rem 0.5rem 2.5rem
  &:last-child
    border-radius: 0 0 .5rem .5rem
  > .remove-button
    position: absolute
    left: .85rem
    top: 0.75rem
.unit-change-warning
  font-size: .9rem
  color: #333
  strong
    color: red
  .icon
    color: red
    position: relative
    top: 2px
</style>
