<template lang="pug">
section.section.analyze-screen.container
  b-loading(:is-full-page="true" :active.sync="initializing" :can-cancel="false")
  .inner(v-if="!initializing")
    .columns
      .column.is-12.header-flex
        h1.title        
        display-currency-select
    .columns(v-if="!entryCount")
      .column.is-12
        b-message(type="is-info" title="Not enough data" v-if="entryCount < 3")
          | You need at least one net worth entry to start using our projection
          | tools. Once you have one, you'll be able to use our advanced long
          | range simulator tool. Once you have 3 months of data, we'll
          | automatically calculate 12 month projections for you.
    .columns(v-if="entryCount > 2")
      .column.is-12
        .box
          h2.subtitle.with-question-mark
            span.flex-grow 12 Month Projection
            b-select(v-model="currentProjectionMethod")
              option(:value='value' v-for="(name, value) in projectionMethods") {{ name }}
            a(@click="showOneYearInfo")
              b-icon(icon="question-circle")
          loading-wrapper
            basic-projection(:method="currentProjectionMethod")
    .columns(v-if="entryCount")
      .column.is-12
        .box#simulator
          h2.subtitle.with-question-mark
            span.flex-grow Long-range Simulator
            a(@click="showSimulatorConfig = !showSimulatorConfig")
              b-icon.has-text-danger(icon="times" v-if="showSimulatorConfig && !mustConfigureSimulator" title="Cancel")
              b-icon(icon="cog" v-if="!showSimulatorConfig" title="Set up")
          b-message(type="is-info" title="About the Simulator" v-if="mustConfigureSimulator")
            p
              | This tool runs a simulation based on the figures from your last net worth entry.
              | For more accurate forward projections and retirement planning,
              | we need to estimate the level of return and fees from the products
              | that you hold. By entering in that data here, we can generate more
              | accurate long-term projections on how you can expect your wealth to
              | grow.
          simulator-config(v-if="showSimulatorConfig" @saved="onSimulatorConfigSave")
          loading-wrapper
            simulator-output(v-if="!showSimulatorConfig")
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { uniq } from 'lodash'

import DisplayCurrencySelect from '@/components/DisplayCurrencySelect.vue'
import LoadingWrapper from '@/components/LoadingWrapper.vue'
import BasicProjection from '@/components/charts/BasicProjection.vue'
import SimulatorConfig from '@/components/simulator/Config.vue'
import SimulatorOutput from '@/components/simulator/Output.vue'

const regressionHelpText = {
  theilsen: `
    Thiel-Sen is a linear projection technique that can cope with noisy
    data and outliers. The output is easy to understand and predictable.
  `,
  adjusted: `
    This projection mode combines a linear projection with the error from
    that linear projection over the last year. It can be useful for those
    whose income and expenses are strongly tied to the month of the year,
    or those who foresee an overall pattern repeating.
  `,
  exponential: `
    A hybrid approach is taken where assets are projected exponentially
    and liabilities are modelled linearly. This allows acceleration to
    be taken into account. It will only be accurate if your assets are
    increasing or decreasing steadily.
  `,
  quadratic: `
    Quadratic projection tries to fit a complex function to your
    data. It can produce better short-term insights, but tends to be
    extremely unreliable over longer projection windows.
  `
}

export default {
  name: 'ProjectionsView',
  components: {
    DisplayCurrencySelect,
    LoadingWrapper,
    BasicProjection,
    SimulatorConfig,
    SimulatorOutput
  },
  data() {
    return {
      mustConfigureSimulator: false,
      showSimulatorConfig: false,
      projectionMethods: {
        theilsen: 'Linear (Theil-Sen)',
        adjusted: 'Adjusted',
        exponential: 'Exponential',
        quadratic: 'Quadratic'
      },
      currentProjectionMethod:
        localStorage.getItem('projectionPreference') || 'theilsen'
    }
  },
  computed: {
    ...mapState('currencies', ['loadingCurrencies']),
    ...mapState('entries', ['entries', 'loadingEntries']),
    ...mapState('categories', ['loadingCategories']),
    ...mapGetters('categories', ['categoryNeedsConfig']),
    initializing() {
      return (
        this.loadingCurrencies || this.loadingEntries || this.loadingCategories
      )
    },
    entryCount() {
      if (!this.entries.length) {
        return 0
      }
      return uniq(this.entries.map(({ id }) => id.slice(0, 7))).length
    }
  },
  watch: {
    currentProjectionMethod(v) {
      localStorage.setItem('projectionPreference', v)
    },
    initializing: {
      immediate: true,
      handler(isInitializing) {
        if (isInitializing) {
          return
        }
        const lastEntry = this.entries[0]
        if (!lastEntry) {
          return
        }
        this.lazyMigrate().then(() => {
          const checkOK = ({ categoryId }) => {
            if (this.categoryNeedsConfig({ id: categoryId })) {
              this.mustConfigureSimulator = true
              this.showSimulatorConfig = true
            }
          }
          lastEntry.assets.forEach(checkOK)
          lastEntry.liabilities.forEach(checkOK)
        })
      }
    },
    showSimulatorConfig() {
      this.$scrollTo(document.getElementById('simulator'))
    }
  },
  methods: {
    ...mapActions('categories', ['lazyMigrate']),
    showOneYearInfo() {
      this.$buefy.dialog.alert({
        title: 'About this projection',
        message:
          regressionHelpText[this.currentProjectionMethod] ||
          'Choose an option to continue'
      })
    },
    onSimulatorConfigSave() {
      this.showSimulatorConfig = false
      this.mustConfigureSimulator = false
    }
  },
  metaInfo: {
    title: 'Future Analysis'
  }
}
</script>
<style lang="sass" scoped>
.header-flex
  display: flex
  flex-direction: row
  flex-wrap: wrap
  .title
    flex-grow: 1
    white-space: nowrap
.title
  .active
    color: #333
    border-bottom: 3px solid #333
  .inactive
    color: hsl(149, 51%, 41%)
  a, span
    margin-right: 1rem
.loading-wrapper
  position: relative
.with-question-mark
  display: flex
  align-items: center
  .flex-grow
    flex-grow: 1
  .icon
    margin-left: 0.5rem
    cursor: pointer
    color: #555
    &:hover
      color: #2e8e8c
@media screen and (max-width: 600px)
  .header-flex
    .title
      width: auto
      order: 0
    .multiselect
      max-width: 100%
      margin-right: 0
      order: 2
</style>
