<template lang="pug">
.scenario-config
  scenario-editor(ref="editor")
  h3.subtitle Scenarios
  drop-list.scenario-list(:items="getScenarios" @reorder="onSort")
    template(v-slot:item="{item}")
      drag.scenario-item(:key="item.id" :class="{'enabled': item.enabled}" handle=".drag-handle")
        .drag-handle
          b-icon(icon="grip-vertical")
        b-checkbox.toggle(:value="item.enabled" @input="toggleEnabled(item)") {{ item.name }}
        .edit-link(@click="editScenario(item)")
          b-icon.warning(icon="exclamation-circle" v-if="shouldWarn(item)")
          b-icon(icon="cog" v-else)
    template(slot="default")
      b-button.is-primary.scenario-adder(@click="newScenarioForm" :key='1') Add Scenario
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { Drag, DropList } from 'vue-easy-dnd'
import ScenarioEditor from './ScenarioEditor'

export default {
  name: 'ScenariosConfig',
  components: {
    ScenarioEditor,
    Drag,
    DropList
  },
  computed: {
    ...mapGetters('scenarios', ['getScenarios', 'scenarioNeedsUpdate']),
    ...mapState('entries', ['entries'])
  },
  mounted() {
    this.lazyMigrate()
  },
  methods: {
    ...mapActions('scenarios', [
      'toggleEnabled',
      'saveSortOrder',
      'lazyMigrate'
    ]),
    newScenarioForm() {
      this.$refs.editor.newScenario()
    },
    editScenario(scenario) {
      this.$refs.editor.editScenario(scenario)
    },
    onSort($event) {
      const scenarios = this.getScenarios.slice()
      $event.apply(scenarios)
      this.saveSortOrder(scenarios)
    },
    shouldWarn(scenario) {
      return this.scenarioNeedsUpdate(scenario)
    }
  }
}
</script>

<style lang="sass" scoped>
.scenario-config
  margin-top: 1.5rem
  margin-bottom: 1rem
.scenario-list
  display: flex
  flex-wrap: wrap
  align-items: center
  margin: -0.5rem
.scenario-adder
  margin: 0.5rem
.scenario-item
  margin: 0.5rem
  color: #111
  border-radius: 8px
  height: 2.5rem
  display: flex
  align-items: center
.toggle
  margin-right: 0 !important
  padding: 0.5rem
  border: 1px solid rgba(0,0,0,.3)
  border-width: 1px 0 1px 0
  height: 2.5rem
  ::v-deep .control-label
    white-space: nowrap
    max-width: 150px
    overflow: hidden
    text-overflow: ellipsis
  &:hover
    background: #f0f0f0
    ::v-deep *
      color: #444
.drag-handle
  cursor: move
  color: #444
  border: 1px solid rgba(0,0,0,.3)
  border-radius: 8px 0 0 8px
  height: 2.5rem
  display: flex
  align-items: center
.warning
  color: red
.edit-link
  height: 2.5rem
  display: flex
  align-items: center
  justify-content: center
  padding: 0.5rem
  border: 1px solid rgba(0,0,0,.3)
  border-radius: 0 8px 8px 0
  cursor: pointer
  &:hover
    background: #f0f0f0
    .icon
      color: #444
</style>
