<template lang="pug">
.percent-input
  input(v-model="input" type="text" inputmode="decimal" :style="inputStyle" placeholder="0")
  span.percent %
</template>
<script>
export default {
  name: 'TextualPercent',
  props: {
    value: String
  },
  data() {
    return {
      input: '0',
      inputStyle: 'width: 2ch'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.input = value
        this.inputStyle = `width: ${Math.max(1, this.input.length)}ch`
      }
    },
    input() {
      this.inputStyle = `width: ${Math.max(1, this.input.length)}ch`
      if (this.input === this.value) {
        return
      }
      if (this.input === '') {
        this.$emit('input', '0')
      } else if (this.input.match(/^0+[1-9]+(\.[0-9]*?)?$/)) {
        this.$emit('input', this.input.replace(/^0+/, ''))
      } else if (this.input.match(/^[0-9]+(\.[0-9]*?)?$/)) {
        this.$emit('input', this.input)
      } else {
        // Reject the change
        this.input = this.value
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.percent-input
  margin: 0 0.375rem
  font-family: monospace
  position: relative
  top: 2px
.percent
  margin-left: 0.25rem
input
  font-family: monospace
  border: none
  border-bottom: 2px solid #aaa
  font-weight: bold
  font-size: 1rem
  background: none
  padding: 0
  &:focus
    outline: none
    border-color: #333
</style>
